export const MassageCategories = [
  {
    name: "Relax",
    items: [
      {
        name: "30 min",
        price: 15,
      },
      {
        name: "1 h",
        price: 30,
      },
    ],
  },
  {
    name: "Terapeutska",
    items: [
      {
        name: "30 min",
        price: 20,
      },
      {
        name: "1 h",
        price: 35,
      },
    ],
  },
  {
    name: "Sportska",
    items: [
      {
        name: "30 min",
        price: 25,
      },
      {
        name: "1 h",
        price: 40,
      },
    ],
  },
  {
    name: "Masaža sa vulkanskim kamenjem",
    items: [
      {
        name: "75 min",
        price: 50,
      },
    ],
  },
  {
    name: "Refleksologija stopala",
    items: [
      {
        name: "30 min",
        price: 20,
      },
    ],
  },
  {
    name: "Aroma terapija",
    items: [
      {
        name: "30 min",
        price: 20,
      },
      {
        name: "1 h",
        price: 35,
      },
    ],
  },
  {
    name: "Masaža svijećama",
    items: [
      {
        name: "30 min",
        price: 25,
      },
      {
        name: "1 h",
        price: 40,
      },
    ],
  },
  {
    name: "Limfna drenaža",
    items: [
      {
        name: "45 min",
        price: 30,
      },
    ],
  },
  {
    name: "Masaža lica",
    items: [
      {
        name: "25 min",
        price: 15,
      },
    ],
  },
  {
    name: "Anticelulit",
    items: [
      {
        name: "40 min",
        price: 15,
      },
    ],
  },

  {
    name: "Parcijalna",
    items: [
      {
        name: "30 min",
        price: 20,
      },
    ],
  },

  {
    name: "Piling",
    items: [
      {
        name: "30 min",
        price: 20,
      },
    ],
  },
];
